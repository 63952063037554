import React from 'react'
import PropTypes from 'prop-types'
import { motion, AnimatePresence } from 'framer-motion'
import Lottie from 'react-lottie-player'
import animation from './lottie/logo.json'
import * as styles from './LogoAnimated.styles.scss'

const LogoAnimated = ({ isVisible }) => {
  return (
    <div className={styles.LogoAnimated} data-scrolled={isVisible}>
      <div className={styles.icon}>
        <AnimatePresence initial={false}>
          {isVisible && (
            <motion.div
              className={styles.icon__inner}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="animated-logo"
            >
              <Lottie animationData={animation} play loop={false} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className={styles.scroll} />
    </div>
  )
}

LogoAnimated.defaultProps = {
  isVisible: false,
}

LogoAnimated.propTypes = {
  isVisible: PropTypes.bool,
}

export { LogoAnimated }
