const variants = {
  lineInner: {
    hidden: {
      y: '110%',
      rotate: 10,
    },
    visible: ({ delay }) => ({
      y: '0%',
      rotate: 0,
      transition: {
        delay: 0.5,
        duration: 0.5 + delay,
        ease: [0.645, 0.045, 0.355, 1],
      },
    }),
  },
}
export default variants
