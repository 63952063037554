import React from 'react'
import PropTypes from 'prop-types'
import { Section } from '@/components/Section'
import { SectionRouter } from '@/components/SectionRouter'

import { FaqHeader } from '@/sections/FaqHeader'
import { Cta } from '@/sections/Cta'
import { Text } from '@/sections/Text'
import { Image } from '@/sections/Image'

import * as styles from './Faqs.styles.scss'

const Faqs = ({ faqs }) => {
  return (
    <Section hasPaddingTop={false} bgColor="white">
      {faqs.map((faq, index) => {
        return (
          <div className={styles.faq} key={faq.id}>
            <FaqHeader
              title={faq.title}
              intro={faq.intro}
              url={faq.url}
              // counter={index + 1}
            />
            <SectionRouter
              types={{
                text: Text,
                image: Image,
                cta: Cta,
              }}
              sections={faq.sections}
            />
          </div>
        )
      })}
    </Section>
  )
}

Faqs.defaultProps = {
  faqs: [],
}

Faqs.propTypes = {
  faqs: PropTypes.arrayOf(PropTypes.object),
}

export { Faqs }
