// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var inner = "J_bv";
export var innerGreen = "J_bY";
export var bg = "J_M";
export var text = "J_bZ";
export var image = "J_Z";
export var imageBottom = "J_b0";
export var imageMiddle = "J_b1";
export var heading = "J_r";
export var headingGreen = "J_bF";
export var headingBlack = "J_b2";
export var headingWhite = "J_b3";
export var aniSpin = "J_l";