// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var inner = "F_bv";
export var success__description = "F_bV";
export var aniSpin = "F_l";