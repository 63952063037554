import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { H1 } from '@/components/Heading'
import { Section } from '@/components/Section'
import { Icon } from '@/components/Icon'
import { Divider } from '@/components/Divider'
import { RichText } from '@/components/RichText'
import * as styles from './PageHeader.styles.scss'

const PageHeader = ({ heading, subheading, description, icon, bgColor }) => {
  return (
    <Section className={styles.pageHeader} bgColor={bgColor}>
      <Container>
        <H1
          className={classnames(styles.heading, {
            [styles.headingGreen]: bgColor === 'white',
          })}
        >
          <div className={styles.heading__text}>
            {heading}
            {subheading && (
              <div className={styles.subheading}>{subheading}</div>
            )}
            {icon && icon !== 'none' && (
              <div className={styles.icon}>
                <Icon uid={icon} />
              </div>
            )}
          </div>
        </H1>
        {description && (
          <>
            <Divider />
            <div className={styles.description}>
              <div className={styles.description__text}>
                <RichText>{description}</RichText>
              </div>
            </div>
          </>
        )}
      </Container>
    </Section>
  )
}

PageHeader.propTypes = {
  heading: PropTypes.node,
  subheading: PropTypes.node,
  description: PropTypes.node,
  icon: PropTypes.string,
  bgColor: PropTypes.string,
}

export { PageHeader }
