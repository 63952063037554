import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store/browserState'
import jump from 'jump.js'

import { Img } from '@/components/Img'
import { LogoAnimated } from '@/components/LogoAnimated'
import { Section } from '@/components/Section'
import { Container } from '@/components/Container'

import ArrowSvg from '@/assets/arrow-down.inline.svg'
import * as styles from './LogoAnimation.styles.scss'
// import variants from './LogoAnimation.motion'

const LogoAnimation = ({ scrollId, image, imageMobile }) => {
  const { device } = useRecoilValue(browserState)
  const { ref, inView } = useInView()
  const currentImage =
    device === 'mobile' && !!imageMobile ? imageMobile : image

  const handleScrollDown = () => jump(`#${scrollId}-end`, { offset: 10 })

  return (
    <Section
      className={styles.LogoAnimation}
      bgColor="custom"
      hasPaddingTop={false}
      hasPaddingBottom={false}
    >
      <div className={styles.sticky}>
        <div className={styles.scrollProgressBar} />
        <div className={styles.image}>
          <div className={styles.scrollDown}>
            <Container>
              <button
                type="button"
                className={styles.scrollDown__button}
                onClick={handleScrollDown}
                aria-label="Scroll down"
              >
                <ArrowSvg />
              </button>
            </Container>
          </div>
          <Img
            {...currentImage}
            isFull
            objectFit="cover"
            objectPosition="center"
            key={`${device}Image`}
          />
          <div className={styles.logo}>
            <LogoAnimated isVisible={inView} />
          </div>
        </div>
        <div className={styles.scroll}>
          <div className={styles.scroll__logoDetect} ref={ref} id={scrollId} />
        </div>
        <div id={`${scrollId}-end`} />
      </div>
    </Section>
  )
}

LogoAnimation.defaultProps = {
  id: 'logo-animation',
  image: {},
  imageMobile: {},
}

LogoAnimation.propTypes = {
  id: PropTypes.string,
  image: PropTypes.object,
  imageMobile: PropTypes.object,
}

export { LogoAnimation }
