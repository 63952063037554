import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store/browserState'

import { Container } from '@/components/Container'
import { H2 } from '@/components/Heading'
import { Section } from '@/components/Section'
import { BodyText } from '@/components/BodyText'
import { Img } from '@/components/Img'
import { Divider } from '@/components/Divider'

import variants from './TextAndImage.motion'
import * as styles from './TextAndImage.styles.scss'

const TextAndImage = ({
  bgColor,
  heading,
  headingColor,
  description,
  image,
  imageAlign,
  borderTop,
}) => {
  const { device } = useRecoilValue(browserState)
  const { ref, inView } = useInView({ triggerOnce: true })

  return (
    <Section>
      <Container>
        {borderTop && <Divider />}
        <motion.div
          ref={ref}
          className={classnames(styles.inner, {
            [styles.innerGreen]: bgColor === 'green',
          })}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
        >
          {bgColor === 'green' && (
            <motion.div
              className={styles.bg}
              variants={device === 'desktop' ? variants.bg : null}
              key={device}
            />
          )}
          <div className={styles.text}>
            {heading && (
              <H2
                className={classnames(styles.heading, {
                  [styles.headingGreen]: headingColor === 'green',
                  [styles.headingBlack]: headingColor === 'black',
                  [styles.headingWhite]: headingColor === 'white',
                })}
                type="h2"
              >
                {heading}
              </H2>
            )}
            <BodyText>{description}</BodyText>
          </div>
          {image && image.src && (
            <motion.div
              variants={bgColor === 'green' ? variants.image : null}
              className={classnames(styles.image, {
                [styles.imageMiddle]: imageAlign === 'middle',
                [styles.imageBottom]: imageAlign === 'bottom',
              })}
            >
              <Img {...image} />
            </motion.div>
          )}
        </motion.div>
      </Container>
    </Section>
  )
}

TextAndImage.propTypes = {
  bgColor: PropTypes.oneOf(['none', 'green']),
  heading: PropTypes.node,
  headingColor: PropTypes.oneOf(['black', 'green', 'white']),
  imageAlign: PropTypes.oneOf(['top', 'middle', 'bottom']),
  description: PropTypes.node,
  image: PropTypes.object,
  borderTop: PropTypes.bool,
}

export { TextAndImage }
