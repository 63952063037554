// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var pageHeader = "z_bD";
export var heading = "z_r";
export var headingGreen = "z_bF";
export var heading__text = "z_bG";
export var icon = "z_n";
export var subheading = "z_bz";
export var description = "z_W";
export var description__text = "z_bH";
export var aniSpin = "z_l";