import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import { RandomReveal } from 'react-random-reveal'
import { Container } from '@/components/Container'
import { Section } from '@/components/Section'
import { H2, H4 } from '@/components/Heading'
import { RichText } from '@/components/RichText'

import * as styles from './Stats.styles.scss'

const Stats = ({ heading, items }) => {
  const { ref, inView } = useInView()

  return (
    <Section>
      <Container className={styles.container}>
        <div className={styles.inner}>
          {!!heading && <H2 className={styles.heading}>{heading}</H2>}
          <div ref={ref}>
            {items.map((item) => {
              const { id, number, description } = item
              return (
                <div key={id} className={styles.row}>
                  <H4 type="h1" aria-label={number}>
                    <RandomReveal
                      isPlaying={inView}
                      key={inView}
                      duration={1}
                      revealDuration={0.5}
                      characters={number}
                      characterSet={['2', '3', '4', '5', '6', '7', '8', '9']}
                    />
                  </H4>
                  {!!description && (
                    <div className={styles.description}>
                      <RichText>{description}</RichText>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </Container>
    </Section>
  )
}

Stats.defaultProps = {
  heading: undefined,
  items: [],
}

Stats.propTypes = {
  heading: PropTypes.node,
  items: PropTypes.array,
}

export { Stats }
