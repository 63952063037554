import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useInView } from 'react-intersection-observer'
import * as styles from './Divider.styles.scss'

const Divider = ({ className }) => {
  const { ref, inView } = useInView()

  return (
    <div
      className={classnames(styles.Divider, className, {
        [styles.isInView]: inView,
      })}
      ref={ref}
    />
  )
}

Divider.propTypes = {
  className: PropTypes.string,
}

export { Divider }
