import React from 'react'
import PropTypes from 'prop-types'
import jump from 'jump.js'
import { motion } from 'framer-motion'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { registerState } from '@/store/registerState'
import { browserState } from '@/store/browserState'
import { getBtnColors } from '@/utils/getBtnColors'

import { AnimatedTextLines } from '@/components/AnimatedTextLines'
import { Btn } from '@/components/Btn'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import { Section } from '@/components/Section'

import ArrowSvg from '@/assets/arrow-down.inline.svg'
import * as styles from './Hero.styles.scss'
import variants from './Hero.motion'

const IS_BROWSER = typeof window !== 'undefined'

const Hero = ({ heading, registerBtnLabel, scrollToId }) => {
  const { device, viewportHeight } = useRecoilValue(browserState)
  const handleScrollDown = () => jump(`#${scrollToId}`, { offset: 10 })
  const setIsRegisterOpen = useSetRecoilState(registerState)
  const handleClickRegister = () => {
    const analyticsData = {
      eventCategory: 'Register',
      eventAction: 'Opened Popup in Hero',
    }
    if (IS_BROWSER && window.gtag) {
      window.gtag('event', 'click', analyticsData)
    } else {
      console.log(analyticsData, 'click')
      console.warn('gtag events only work in production')
    }

    setIsRegisterOpen(true)
  }

  return (
    <Section
      className={styles.Hero}
      bgColor="custom"
      hasPaddingTop={false}
      hasPaddingBottom={false}
    >
      <motion.div className={styles.intro} initial="hidden" animate="visible">
        <div
          className={styles.intro__inner}
          style={{
            minHeight: device === 'mobile' ? `${viewportHeight}px` : null,
          }}
        >
          <Heading tag="div" className={styles.head} type="h1">
            <div className={styles.head__inner}>
              <Container>
                <h2 className={styles.heading}>
                  <AnimatedTextLines
                    text={heading}
                    length={{ desktop: 24, mobile: 12 }}
                  />
                </h2>
                <div className={styles.register}>
                  <motion.div
                    className={styles.register__inner}
                    variants={variants.register}
                    custom={{ delay: 1.2 }}
                  >
                    <Btn
                      onClick={handleClickRegister}
                      theme={getBtnColors('green')}
                    >
                      {registerBtnLabel}
                    </Btn>
                  </motion.div>
                </div>
              </Container>
            </div>
            {scrollToId && (
              <div className={styles.footer}>
                <Container className={styles.footer__container}>
                  <motion.button
                    type="button"
                    className={styles.scrollDown}
                    onClick={handleScrollDown}
                    variants={variants.scrollDown}
                    custom={{ delay: 3 }}
                    aria-label="Scroll down"
                  >
                    <ArrowSvg />
                  </motion.button>
                </Container>
              </div>
            )}
          </Heading>
        </div>
      </motion.div>
    </Section>
  )
}

Hero.propTypes = {
  heading: PropTypes.string,
  scrollToId: PropTypes.string,
  registerBtnLabel: PropTypes.string,
}

export { Hero }
