// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var blogPosts = "s_1";
export var heading = "s_r";
export var error = "s_p";
export var post = "s_2";
export var post__heading = "s_3";
export var post__heading_text = "s_4";
export var post__date = "s_5";
export var post__header = "s_6";
export var post__synopsis = "s_7";
export var post__synopsis__text = "s_8";
export var post__icon = "s_9";
export var link = "s_bb";
export var aniSpin = "s_l";