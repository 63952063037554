import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { getBtnColors } from '@/utils/getBtnColors'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store/browserState'

import { Btn } from '@/components/Btn'
import { BodyText } from '@/components/BodyText'
import { Heading } from '@/components/Heading'
import { Container } from '@/components/Container'
import { Img } from '@/components/Img'
import { Section } from '@/components/Section'
import { Icon } from '@/components/Icon'

import variants from './Feature.motion'
import * as styles from './Feature.styles.scss'

const Feature = ({
  id,
  bgColor,
  heading,
  description,
  icon,
  image,
  intro,
  link,
  subheading,
}) => {
  const { device } = useRecoilValue(browserState)
  const { ref, inView } = useInView()

  return (
    <Section className={styles.Feature} bgColor={bgColor}>
      <motion.div
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
        ref={ref}
      >
        <Container>
          <div className={styles.inner} id={`${id}-inner`}>
            <div className={styles.column}>
              <div className={styles.sticky}>
                <div className={styles.column__inner}>
                  {subheading && (
                    <h3 className={styles.subheading}>{subheading}</h3>
                  )}
                  <Heading
                    tag={subheading ? 'h4' : 'h3'}
                    type="h2"
                    className={styles.heading}
                  >
                    {heading}
                  </Heading>
                  {icon && icon !== 'none' && (
                    <motion.div
                      className={styles.icon}
                      variants={variants.icon}
                    >
                      <Icon uid={icon} />
                    </motion.div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.column__inner} key={device}>
                {subheading && <div className={styles.subheading} />}
                {intro && (
                  <motion.div
                    variants={variants.text}
                    custom={{ yShift: device === 'mobile' ? 20 : 100 }}
                  >
                    <BodyText type="p1" className={styles.textBlock}>
                      {intro}
                    </BodyText>
                  </motion.div>
                )}
                {image && image.src && (
                  <motion.div
                    className={styles.image}
                    variants={variants.text}
                    custom={{ yShift: device === 'mobile' ? 20 : 100 }}
                  >
                    <Img {...image} />
                  </motion.div>
                )}
                {description && (
                  <motion.div
                    variants={variants.text}
                    className={styles.description}
                    custom={{ yShift: device === 'mobile' ? 20 : 100 }}
                  >
                    <BodyText type="p1" className={styles.textBlock}>
                      {description}
                    </BodyText>
                  </motion.div>
                )}
                {link && link.url && (
                  <div className={styles.link}>
                    <motion.div
                      variants={variants.link}
                      className={styles.link__inner}
                    >
                      <Btn
                        href={link.url}
                        target={link.target}
                        theme={getBtnColors(bgColor)}
                      >
                        {link.title}
                      </Btn>
                    </motion.div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>
        <motion.div className={styles.divider} variants={variants.divider} />
      </motion.div>
    </Section>
  )
}

Feature.defaultProps = {
  id: 'feature-section',
  bgColor: 'green',
  image: {},
  link: {},
}

Feature.propTypes = {
  id: PropTypes.string,
  bgColor: PropTypes.string,
  heading: PropTypes.node,
  description: PropTypes.node,
  icon: PropTypes.string,
  intro: PropTypes.node,
  image: PropTypes.object,
  link: PropTypes.object,
  subheading: PropTypes.node,
}

export { Feature }
