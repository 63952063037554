import React from 'react'
import PropTypes from 'prop-types'
import { SectionRouter } from '@/components/SectionRouter'
import { H1 } from '@/components/Heading'

import { BlogPosts } from '@/sections/BlogPosts'
import { Faqs } from '@/sections/Faqs'
import { Feature } from '@/sections/Feature'
import { Hero } from '@/sections/Hero'
import { Image } from '@/sections/Image'
import { ImageComparisonSlider } from '@/sections/ImageComparisonSlider'
import { Info } from '@/sections/Info'
import { Intro } from '@/sections/Intro'
import { LogoAnimation } from '@/sections/LogoAnimation'
import { PageHeader } from '@/sections/PageHeader'
import { PageLink } from '@/sections/PageLink'
import { RegisterForm } from '@/sections/RegisterForm'
import { Stats } from '@/sections/Stats'
import { TextAndImage } from '@/sections/TextAndImage'
import { Video } from '@/sections/Video'

import * as styles from './Page.styles.scss'

const Page = ({ sections }) => {
  return (
    <div>
      {sections.length ? (
        <SectionRouter
          types={{
            blog_posts: BlogPosts,
            faqs: Faqs,
            feature: Feature,
            header: PageHeader,
            hero: Hero,
            image_comparison_slider: ImageComparisonSlider,
            image: Image,
            info: Info,
            intro: Intro,
            logo_animation: LogoAnimation,
            page_link: PageLink,
            register_form: RegisterForm,
            stats: Stats,
            text_and_image: TextAndImage,
            video: Video,
          }}
          sections={sections}
        />
      ) : (
        <div className={styles.empty}>
          <H1>Coming Soon</H1>
        </div>
      )}
    </div>
  )
}

Page.defaultProps = {
  sections: [],
}

Page.propTypes = {
  sections: PropTypes.array,
}

export default Page
