// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var Hero = "w_bj";
export var intro = "w_C";
export var intro__inner = "w_bk";
export var head = "w_w";
export var head__inner = "w_x";
export var register = "w_bl";
export var register__inner = "w_bm";
export var heading = "w_r";
export var footer = "w_bn";
export var footer__container = "w_bp";
export var scrollDown = "w_bq";
export var aniSpin = "w_l";