import React from 'react'
import PropTypes from 'prop-types'
import { Btn } from '@/components/Btn'
import { RichText } from '@/components/RichText'
import { Container } from '@/components/Container'
import { Section } from '@/components/Section'
import { getBtnColors } from '@/utils/getBtnColors'
import { Img } from '@/components/Img'
import * as styles from './Intro.styles.scss'

const Intro = ({ bgColor, heading, description, image, link, subheading }) => {
  return (
    <Section className={styles.intro} bgColor={bgColor}>
      <Container className={styles.container}>
        {image && image.src && (
          <Img {...image} isFull objectFit="cover" objectPosition="center" />
        )}
        <div className={styles.inner}>
          <div className={styles.header}>
            {subheading && <h4 className={styles.subheading}>{subheading}</h4>}
          </div>
          <h2 className={styles.heading}>{heading}</h2>
          {description && (
            <div className={styles.description}>
              <div>
                <RichText>{description}</RichText>
              </div>
              {link && link.url && (
                <div className={styles.link}>
                  <Btn
                    href={link.url}
                    target={link.target}
                    theme={getBtnColors(bgColor)}
                  >
                    {link.title}
                  </Btn>
                </div>
              )}
            </div>
          )}
        </div>
      </Container>
    </Section>
  )
}

Intro.defaultProps = {
  bgColor: 'green',
  heading: undefined,
  description: undefined,
  image: {},
  link: {},
  subheading: undefined,
}

Intro.propTypes = {
  bgColor: PropTypes.string,
  heading: PropTypes.node,
  description: PropTypes.node,
  image: PropTypes.object,
  link: PropTypes.object,
  subheading: PropTypes.node,
}

export { Intro }
