const ease = [0.645, 0.045, 0.355, 1]

const variants = {
  bg: {
    hidden: {
      scaleX: 0.5,
    },
    visible: {
      scaleX: 1,
      transition: {
        duration: 1,
        delay: 0.1,
        ease,
      },
    },
  },
  image: {
    hidden: {
      opacity: 0,
      scale: 0.75,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.5 + 0.1,
        ease,
      },
    },
  },
}

export default variants
