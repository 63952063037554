// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var container = "B_q";
export var inner = "B_bv";
export var header = "B_bJ";
export var subheading = "B_bz";
export var heading = "B_r";
export var description = "B_W";
export var intro = "B_C";
export var link = "B_bb";
export var aniSpin = "B_l";