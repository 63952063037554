import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import { install } from 'resize-observer'
import { ImgComparisonSlider } from '@img-comparison-slider/react'
import { Section } from '@/components/Section'
import { Container } from '@/components/Container'
import { RichText } from '@/components/RichText'
import { Img } from '@/components/Img'
import * as styles from './ImageComparisonSlider.styles.scss'
import HandleSvg from './images/handle.inline.svg'

const ImageComparisonSlider = ({ bgColor, image1, image2, description }) => {
  // resize-observer polyfill for iOS Safari
  if (typeof window !== 'undefined') install()

  // to prevent performance issues only show slider if in view
  const { ref, inView } = useInView()

  return (
    <Section bgColor={bgColor} className={styles.ImageComparisonSlider}>
      <Container className={styles.container}>
        <div ref={ref}>
          {inView ? (
            <ImgComparisonSlider className={styles.slider}>
              <div className={styles.image} slot="first">
                <Img {...image1} isFull objectFit="cover" />
              </div>
              <div className={styles.image} slot="second">
                <Img {...image2} isFull objectFit="cover" />
              </div>
              <div className={styles.handle} slot="handle">
                <HandleSvg />
              </div>
            </ImgComparisonSlider>
          ) : (
            <div className={styles.sliderPlaceholder} />
          )}
          <div className={styles.description}>
            <RichText>{description}</RichText>
          </div>
        </div>
      </Container>
    </Section>
  )
}

ImageComparisonSlider.propTypes = {
  bgColor: PropTypes.string,
  image1: PropTypes.object,
  image2: PropTypes.object,
}

export { ImageComparisonSlider }
