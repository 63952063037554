import React, { useState } from 'react'
import classnames from 'classnames'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { Link } from '@/components/TransitionLink'
import { Section } from '@/components/Section'

import variants from './PageLink.motion'
import * as styles from './PageLink.styles.scss'

const PageLink = ({ link, label }) => {
  const [isHovering, setHovering] = useState(false)

  return (
    <Section hasPaddingTop={false} hasPaddingBottom={false} bgColor="black">
      {link && (
        <Link
          to={link.url}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          aria-label={`${link.title} - Learn more`}
        >
          <motion.div
            className={classnames({ [styles.isHovering]: isHovering })}
            initial="initial"
            animate={isHovering ? 'hovering' : 'initial'}
          >
            <motion.div
              className={styles.bgHover}
              variants={variants.bgHover}
            />
            <Container className={styles.container}>
              <motion.div className={styles.inner} variants={variants.inner}>
                {label && <div>{label}</div>}
                {link && link.url && (
                  <div className={styles.linkTitle}>{link.title}</div>
                )}
              </motion.div>
            </Container>
          </motion.div>
        </Link>
      )}
    </Section>
  )
}

PageLink.propTypes = {
  link: PropTypes.object,
  label: PropTypes.string,
}

export { PageLink }
