import React from 'react'
import PropTypes from 'prop-types'

import { Container } from '@/components/Container'
import { H1 } from '@/components/Heading'
import { Link } from '@/components/TransitionLink'
import { Section } from '@/components/Section'
import { RichText } from '@/components/RichText'
import { Btn } from '@/components/Btn'
import { getBtnColors } from '@/utils/getBtnColors'

import * as styles from './BlogPosts.styles.scss'
import LinkSvg from './images/link.inline.svg'

const BlogPosts = ({ heading, posts, id, link }) => {
  return (
    <Section className={styles.blogPosts} bgColor="black" id={id}>
      <Container>
        <H1 className={styles.heading}>{heading}</H1>
        {posts.length ? (
          <div>
            <div>
              {posts.map((post) => {
                return (
                  <Link
                    to={post.path}
                    aria-label="Read more"
                    key={post.id}
                    className={styles.post}
                  >
                    <div className={styles.post__date}>{post.date}</div>
                    <div className={styles.post__header}>
                      <h4 className={styles.post__heading}>
                        <div className={styles.post__heading_text}>
                          {post.title}
                        </div>
                      </h4>
                      <div className={styles.post__synopsis}>
                        <div className={styles.post__synopsis__text}>
                          <RichText>{post.synopsis}</RichText>
                        </div>
                        <div className={styles.post__icon}>
                          <LinkSvg />
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })}
            </div>
            {link && link.url && (
              <div className={styles.link}>
                <Btn
                  href={link.url}
                  target={link.target}
                  theme={getBtnColors('black')}
                >
                  {link.title}
                </Btn>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.error}>
            Please check back later for news and updates.
          </div>
        )}
      </Container>
    </Section>
  )
}

BlogPosts.defaultProps = {
  heading: undefined,
  posts: [],
  id: 'blog-posts',
  link: {},
}

BlogPosts.propTypes = {
  heading: PropTypes.node,
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  id: PropTypes.string,
  link: PropTypes.object,
}

export { BlogPosts }
