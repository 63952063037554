const ease = [0.645, 0.045, 0.355, 1]

const variants = {
  divider: {
    hidden: {
      scaleY: 0.75,
      opacity: 0,
    },
    visible: {
      scaleY: 1,
      opacity: 1,
      transition: {
        duration: 1,
        ease,
      },
    },
  },
  icon: {
    hidden: {
      scale: 0.75,
      opacity: 0,
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.12,
        ease,
      },
    },
  },
  text: {
    hidden: ({ yShift = 100 }) => ({
      y: yShift,
      opacity: 0,
    }),
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.75,
        delay: 0.25,
        ease,
      },
    },
  },
  link: {
    hidden: {
      scale: 0.5,
      opacity: 0,
      translateZ: '1px',
    },
    visible: {
      scale: 1,
      opacity: 1,
      translateZ: '1px',
      transition: {
        duration: 0.5,
        delay: 0.5,
        ease,
      },
    },
  },
}

export default variants
