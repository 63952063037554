const ease = [0.645, 0.045, 0.355, 1]

const variants = {
  bgHover: {
    initial: {
      scaleY: 0,
    },
    hovering: {
      scaleY: 1,
      transition: {
        ease,
      },
    },
  },
  inner: {
    initial: {
      y: 0,
    },
    hovering: {
      y: -25,
    },
  },
}

export default variants
