// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var container = "H_q";
export var inner = "H_bv";
export var heading = "H_r";
export var heading__counter = "H_bX";
export var heading__text = "H_bG";
export var intro = "H_C";
export var aniSpin = "H_l";