// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var borderTop = "v_bf";
export var heading = "v_r";
export var head = "v_w";
export var image = "v_Z";
export var intro = "v_C";
export var icon = "v_n";
export var main = "v_bg";
export var bodyText = "v_bh";
export var aniSpin = "v_l";