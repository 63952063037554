const variants = {
  register: {
    hidden: {
      opacity: 0,
      scale: 0.75,
    },
    visible: ({ delay }) => ({
      opacity: 1,
      scale: 1,
      transition: {
        delay: delay,
        duration: 0.5,
        ease: [0.645, 0.045, 0.355, 1],
      },
    }),
  },
  scrollDown: {
    hidden: {
      opacity: 0,
      scale: 0.5,
      y: 50,
    },
    visible: ({ delay }) => ({
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        delay: delay,
        duration: 0.8,
        ease: [0.645, 0.045, 0.355, 1],
      },
    }),
  },
}
export default variants
