// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var LogoAnimation = "C_bK";
export var sticky = "C_by";
export var scroll = "C_bL";
export var scrollProgressBar = "C_bM";
export var scroll__logoDetect = "C_bN";
export var logo = "C_bP";
export var image = "C_Z";
export var scrollDown = "C_bq";
export var scrollDown__button = "C_bQ";
export var aniSpin = "C_l";