import React from 'react'
import PropTypes from 'prop-types'
import { BodyText } from '@/components/BodyText'
import { Container } from '@/components/Container'
import { H3 } from '@/components/Heading'
import { Icon } from '@/components/Icon'
import { Img } from '@/components/Img'
import { Section } from '@/components/Section'
import { Divider } from '@/components/Divider'
import * as styles from './Info.styles.scss'

const Info = ({ heading, intro, image, bodyText, icon, borderTop }) => {
  return (
    <Section>
      <Container>
        {borderTop && <Divider className={styles.borderTop} />}
        {image && image.src && (
          <div className={styles.image}>
            <Img {...image} isFull objectFit="cover" />
            {icon && icon !== 'none' && (
              <div className={styles.icon}>
                <Icon uid={icon} />
              </div>
            )}
          </div>
        )}
        <div className={styles.head}>
          <div className={styles.heading}>
            {heading && <H3 type="h2">{heading}</H3>}
          </div>
          {intro && <BodyText className={styles.intro}>{intro}</BodyText>}
        </div>
        {bodyText && (
          <div className={styles.main}>
            <BodyText className={styles.bodyText}>{bodyText}</BodyText>
          </div>
        )}
      </Container>
    </Section>
  )
}

Info.propTypes = {
  heading: PropTypes.node,
  intro: PropTypes.node,
  image: PropTypes.object,
  icon: PropTypes.string,
  bodyText: PropTypes.node,
  borderTop: PropTypes.bool,
}

export { Info }
