// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var Feature = "y_bs";
export var divider = "y_bt";
export var inner = "y_bv";
export var column = "y_bw";
export var column__inner = "y_bx";
export var sticky = "y_by";
export var subheading = "y_bz";
export var heading = "y_r";
export var description = "y_W";
export var textBlock = "y_bB";
export var link = "y_bb";
export var link__inner = "y_bC";
export var image = "y_Z";
export var icon = "y_n";
export var aniSpin = "y_l";