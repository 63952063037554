import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { get } from '@/utils/get'
import { getSlug } from '@/utils/getSlug'
import { sectionSerializer } from '@/prismic/utils/sectionSerializer'
import { linkResolver } from '@/prismic/utils/linkResolver'
import PageTemplate from '@/templates/Page'

const PagePrismic = ({ data }) => {
  const { prismicPage } = data
  const slices = get(prismicPage, 'data.body') || []
  const bgColor = getSlug(prismicPage.data.bg)
  const sections = sectionSerializer({ slices, pageBgColor: bgColor })

  return <PageTemplate sections={sections} />
}

PagePrismic.propTypes = {
  data: PropTypes.object.isRequired,
}

export default withPrismicPreview(PagePrismic, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver,
  },
])

export const query = graphql`
  query PageByID($id: String!) {
    prismicPage(id: { eq: $id }) {
      _previewable
      uid
      id
      type
      data {
        title
        bg
        body {
          ... on PrismicPageDataBodyHero {
            id
            slice_type
            primary {
              heading {
                text
              }
              register_button_label
            }
          }
          ... on PrismicPageDataBodyLogoAnimation {
            id
            slice_type
            primary {
              image {
                fluid(maxWidth: 2000) {
                  ...GatsbyImgixFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              image_mobile {
                fluid(maxWidth: 1000) {
                  ...GatsbyImgixFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicPageDataBodyImage {
            id
            slice_type
            primary {
              background_color
              caption {
                raw
              }
              image {
                fluid(maxWidth: 2000) {
                  ...GatsbyImgixFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicPageDataBodyFeature {
            id
            slice_type
            primary {
              subheading {
                raw
              }
              heading {
                raw
              }
              intro {
                raw
              }
              description {
                raw
              }
              link {
                document {
                  ... on PrismicPage {
                    uid
                    type
                  }
                }
                link_type
                target
                url
              }
              link_label
              background_color
              icon
              image {
                fluid(maxWidth: 2000) {
                  ...GatsbyImgixFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicPageDataBodyIntro {
            id
            slice_type
            primary {
              subheading {
                raw
              }
              heading {
                raw
              }
              description {
                raw
              }
              link {
                document {
                  ... on PrismicPage {
                    uid
                    type
                  }
                }
                link_type
                target
                url
              }
              link_label
              background_color
              image {
                fluid(maxWidth: 2000) {
                  ...GatsbyImgixFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicPageDataBodyBlogPosts {
            id
            slice_type
            primary {
              heading {
                raw
              }
              posts
              link {
                document {
                  ... on PrismicPage {
                    uid
                    type
                  }
                }
                link_type
                target
                url
              }
              link_label
            }
          }
          ... on PrismicPageDataBodyStats {
            id
            slice_type
            primary {
              heading {
                raw
              }
            }
            items {
              description {
                raw
              }
              number
            }
          }
          ... on PrismicPageDataBodyTextAndImage {
            id
            slice_type
            primary {
              bg_color
              border_top
              heading {
                raw
              }
              heading_color
              description {
                raw
              }
              image {
                fluid(maxWidth: 1500) {
                  ...GatsbyImgixFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              image_vertical_align
            }
          }
          ... on PrismicPageDataBodyPageLink {
            id
            slice_type
            primary {
              document_link {
                document {
                  ... on PrismicPage {
                    uid
                    data {
                      title
                    }
                  }
                }
              }
              label
            }
          }
          ... on PrismicPageDataBodyHeader {
            id
            slice_type
            primary {
              heading {
                raw
              }
              subheading {
                raw
              }
              description {
                raw
              }
              icon
            }
          }
          ... on PrismicPageDataBodyInfo {
            id
            slice_type
            primary {
              image {
                fluid(maxWidth: 2000) {
                  ...GatsbyImgixFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              heading {
                raw
              }
              intro {
                raw
              }
              body_text {
                raw
              }
              border_top
              icon
            }
          }
          ... on PrismicPageDataBodyImageComparisonSlider {
            id
            slice_type
            primary {
              background_color
              image_01 {
                fluid(maxWidth: 2000) {
                  ...GatsbyImgixFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              image_02 {
                fluid(maxWidth: 2000) {
                  ...GatsbyImgixFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              description {
                raw
              }
            }
          }
          ... on PrismicPageDataBodyFaqs {
            id
            slice_type
            items {
              faq {
                document {
                  ... on PrismicFaq {
                    uid
                    id
                    type
                    data {
                      title
                      body {
                        ... on PrismicFaqDataBodyText {
                          id
                          slice_type
                          primary {
                            body_text {
                              raw
                            }
                          }
                        }
                        ... on PrismicFaqDataBodyImage {
                          id
                          slice_type
                          primary {
                            image {
                              fluid(maxWidth: 2000) {
                                ...GatsbyImgixFluid_noBase64
                              }
                              url
                              alt
                              dimensions {
                                width
                                height
                              }
                            }
                          }
                        }
                        ... on PrismicFaqDataBodyCta {
                          id
                          slice_type
                          primary {
                            link_label
                            link {
                              document {
                                ... on PrismicPage {
                                  uid
                                  type
                                }
                              }
                              link_type
                              target
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyRegisterForm {
            id
            slice_type
          }
          ... on PrismicPageDataBodyVideo {
            id
            slice_type
            primary {
              background_color
              video_url
              aspect_ratio
              poster {
                fluid(maxWidth: 1600) {
                  ...GatsbyImgixFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`
