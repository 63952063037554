import React from 'react'
import PropTypes from 'prop-types'
import { Section } from '@/components/Section'
import { Container } from '@/components/Container'
import { MailchimpForm } from '@/components/MailchimpForm'
import { Heading } from '@/components/Heading'
import * as styles from './RegisterForm.styles.scss'

const RegisterForm = ({ url, fields, successTitle, successDesc }) => (
  <Section bgColor="green">
    <Container>
      <div className={styles.inner}>
        <MailchimpForm
          url={url}
          fields={fields}
          success={
            <div>
              <Heading type="h2">{successTitle}</Heading>
              <div className={styles.success__description}>{successDesc}</div>
            </div>
          }
        />
      </div>
    </Container>
  </Section>
)

RegisterForm.defaultProps = {
  fields: [],
}

RegisterForm.propTypes = {
  url: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.object),
  successTitle: PropTypes.node,
  successDesc: PropTypes.node,
}

export { RegisterForm }
