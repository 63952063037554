import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import chunk from 'chunk-text'
import { motion } from 'framer-motion'
import { useRecoilValue } from 'recoil'
import { browserState } from '@/store/browserState'

import variants from './AnimatedTextLines.motion'
import * as styles from './AnimatedTextLines.styles.scss'

const AnimatedTextLines = ({ text, length }) => {
  const { device } = useRecoilValue(browserState)
  const [lines, setLines] = useState([])

  useLayoutEffect(() => {
    if (typeof text !== 'string' || device === undefined) return

    let currentLength = null
    if (typeof length === 'number') {
      currentLength = length
    } else {
      if (length.desktop && device === 'desktop') {
        currentLength = length.desktop
      } else if (length.mobile && device === 'mobile') {
        currentLength = length.mobile
      }
    }

    setLines(
      typeof currentLength === 'number' ? chunk(text, currentLength) : null
    )
  }, [length, text, device])

  if (lines !== null) {
    return (
      <div aria-label={text}>
        {lines.map((line, index) => {
          return (
            <span
              key={`line-${index}`}
              className={styles.line}
              aria-hidden="true"
            >
              <motion.span
                initial="hidden"
                animate="visible"
                variants={variants.lineInner}
                className={styles.lineInner}
                custom={{ delay: index * 0.25 }}
              >
                {line}
              </motion.span>
            </span>
          )
        })}
      </div>
    )
  }

  return text
}

AnimatedTextLines.defaultProps = {
  length: 24,
}

AnimatedTextLines.propTypes = {
  text: PropTypes.string,
  length: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ mobile: PropTypes.number, desktop: PropTypes.number }),
  ]),
}

export { AnimatedTextLines }
