import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { Section } from '@/components/Section'
import { Link } from '@/components/TransitionLink'
import * as styles from './FaqHeader.styles.scss'

const FaqHeader = ({ title, url, counter }) => {
  return (
    <Section hasPaddingTop={false} hasPaddingBottom={false} bgColor="white">
      <Container className={styles.container}>
        <div className={styles.inner}>
          <h3 className={styles.heading}>
            {!!counter && (
              <div className={styles.heading__counter}>{counter}.</div>
            )}
            <Link to={url}>
              <span className={styles.heading__text}>{title}</span>
            </Link>
          </h3>
        </div>
      </Container>
    </Section>
  )
}

FaqHeader.propTypes = {
  title: PropTypes.node,
  url: PropTypes.string,
}

export { FaqHeader }
